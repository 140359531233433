import React, { useEffect } from "react";
import { Card } from "../../components/card";
// import { RecentActivity } from "../../components/RecentActivity";
import { Announcement } from "../../components/Announcement";
import { UserProfile } from "../components/UserProfile";

import { fetchEmployeeLeaves } from "../../Redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
// import { fetchAttendance } from "../../APIs";

export const EmployeeDash = () => {
  const dispatch = useDispatch();
  const { leaves, status, error } = useSelector((state) => state.user);
  const leavesData = leaves?.data;

  // auto call of view attendance
  // useEffect(()=>{
  //   fetchAttendance()
  // },[])
  useEffect(() => {
    dispatch(fetchEmployeeLeaves());
  }, [dispatch]);

  if (status === "loading") return <p>Loading...</p>;
  if (status === "failed") return <p>Error: {error}</p>;

  return (
    <>
      <div className="container mb-5">
        <UserProfile />
      </div>

      <div className="dashBoard flex flex-col md:flex-col xl:flex-row  text-start px-5  gap-3">
        <div className="max-sm:order-2 sm:order-2 xl:order-1">
          <div className="flex flex-col md:flex-row xl:flex-row  px-2 gap-3 max-sm:justify-center items-center">
            <Card
              title="View Attendance"
              // value={24}
              urgency="View"
              path="/employee/view_attendance"
              bgColor="bg-orange-100"
              textColor="text-gray-900"
              height={150}
            />
            <Card
              title="Leave Balance"
              value={0}
              urgency="View"
              path="/employee"
              bgColor="bg-blue-100"
              height={150}
            />
            <Card
              title="Apply Leave"
              // value={24}
              urgency="View"
              path="/employee/apply_leave"
              bgColor="bg-red-100"
              textColor="text-gray-900"
              height={150}
            />
          </div>
          <div className="Upcoming px-2  mt-2 h-[40vh] overflow-y-auto ">
            <Announcement />
          </div>
        </div>

        {/* <div className="mt-4 md:mt-0 xl:mt-0 ml-2 max-sm:order-1 sm:order-1 xl:order-2">
            <RecentActivity />
          </div> */}

        <div className="mt-4 md:mt-0 xl:mt-0 ml-2 max-sm:order-1 sm:order-1 xl:order-2">
          <div className="w-full">
            {status === "loading" ? (
              <p>Loading...</p> // Show loading state
            ) : status === "failed" ? (
              <p className="text-red-500">{error}</p> // Show error message
            ) : (
              <table className="min-w-full bg-white border border-gray-300 shadow-lg rounded-lg overflow-hidden">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 uppercase">
                      Category
                    </th>
                    <th className="px-4 py-2 text-left text-sm font-medium text-gray-700 uppercase">
                      Leaves
                    </th>
                  </tr>
                </thead>
                <tbody className="text-gray-600">
                  {leavesData &&
                    Object.entries(leavesData)
                      .filter(
                        ([key, value]) =>
                          !(
                            ["id", "user"].includes(key) ||
                            (["N", "ML"].includes(key) && value <= 0)
                          )
                      )
                      .map(([key, value]) => (
                        <tr key={key} className="hover:bg-gray-50">
                          <td className="px-4 py-2">
                            {key === "total_leaves" ? "TOTAL LEAVES" : key}
                          </td>
                          <td className="px-4 py-2">{value}</td>
                        </tr>
                      ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>

      <div>{/* Add content for the second section, if needed */}</div>
    </>
  );
};
