import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { postData1, postData3 } from "../../../APIs";
import { toast } from "react-toastify";

export const EditEmp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let userData = location.state.data;
  let isDeleted = location.state.deleted;
 const profileImg = userData?.image_url

  const initialValue = {
    firstName: userData?.first_name ? userData?.first_name : "",
    lastName: userData?.last_name ? userData?.last_name : "",
    gender: userData?.gender ? userData?.gender : "",
    dob: userData?.dob ? userData?.dob : "",
    mobile: userData?.mobile ? Number(userData?.mobile) : "0",
    email: userData?.email ? userData?.email : "",
    official_mail: userData?.official_mail ? userData?.official_mail : "",
    image:userData?.image ? userData?.image : null,
    joiningDate: userData?.joining_date ? userData?.joining_date : "",
    resignDate: userData?.resign_date ? userData?.resign_date : "",
    totalSalary: userData?.payroll?.total_salary ? Number(userData?.payroll?.total_salary) : "0",
    department: userData?.payroll?.department ? userData?.payroll?.department : "",
    desgination: userData?.desgination ? userData?.desgination : "",
    employeeType: userData?.payroll?.emp_type ? userData?.payroll?.emp_type : "",
    bankName: userData?.payroll?.bank_name ? userData?.payroll?.bank_name : "NA",
    bankAccount: userData?.payroll?.bankac ? Number(userData?.payroll?.bankac) : "0",
    pfNo: userData?.payroll?.pfno ? Number(userData?.payroll?.pfno) : "0",
    pfAmount: userData?.payroll?.pf_amount ? Number(userData?.payroll?.pf_amount) : "0",
    esiAmount: userData?.payroll?.esi_amount ? Number(userData?.payroll?.esi_amount) : "0",
    basic: userData?.payroll?.basic ? Number(userData?.payroll?.basic) : "0",
    DA: userData?.payroll?.DA ? Number(userData?.payroll?.DA) : "0",
    HRA: userData?.payroll?.HRA ? Number(userData?.payroll?.HRA) : "0",
    other: userData?.payroll?.other ? Number(userData?.payroll?.other) : "0",
    medical: userData?.payroll?.medical ? Number(userData?.payroll?.medical) : "0",
    advance: userData?.payroll?.advance ? Number(userData?.payroll?.advance) : "0",
    address: userData?.address ? userData?.address : "",
  };

  const [formData, setFormData] = useState(initialValue);
  const [profileImage, setProfileImage] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfileImage(reader.result); // Set preview URL for the image
      };
      reader.readAsDataURL(file);
  
      // Update the formData with the actual file object
      setFormData({ ...formData, image: file });
    }
  };

  const handleUpdateEmployData = async () => {
    const data = {
      id: userData?.user,
      emp_id: userData?.emp_id,
      total_salary: formData?.totalSalary ? Number(formData?.totalSalary) : userData?.payroll?.total_salary,
      department: formData?.department ? formData?.department : userData?.payroll?.department,
      emp_type: formData?.employeeType ? formData?.employeeType : userData?.payroll?.emp_type,
      bank_name: formData?.bankName ? formData?.bankName : userData?.payroll?.bank_name,
      bankac: formData?.bankAccount ? Number(formData?.bankAccount) : userData?.payroll?.bankac,
      pfno: formData?.pfNo ? Number(formData?.pfNo) : userData?.payroll?.pfno,
      pf_amount: formData?.pfAmount ? Number(formData?.pfAmount) : userData?.payroll?.pf_amount,
      esi_amount: formData?.esiAmount ? Number(formData?.esiAmount) : userData?.payroll?.esi_amount,
      other: formData?.other ? Number(formData?.other) : userData?.payroll?.other,
      DA: formData?.DA ? Number(formData?.DA) : userData?.payroll?.DA,
      HRA: formData?.HRA ? Number(formData?.HRA) : userData?.payroll?.HRA,
      basic: formData?.basic ? Number(formData?.basic) : userData?.payroll?.basic,
      advance: formData?.advance ? Number(formData?.advance) : userData?.payroll?.advance,
      medical: formData?.medical ? Number(formData?.medical) : userData?.payroll?.medical,
    };

    const res = await postData1("api/v1/admin/register_payroll/", data, true);
    if (res.status_code === 200) {
      toast.success("Updated Successfully");
      setTimeout(() => {
        navigate("/admin/employees");
      }, 1000);
    } else {
      toast.error(res.msg ? res.msg : "Something went wrong!");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const data = new FormData();
    data.append("gender", formData.gender);
    data.append("dob", formData.dob);
    data.append("user", userData?.user);
   data.append ("emp_id", userData?.emp_id);
    data.append("first_name", formData.firstName);
    data.append("last_name", formData.lastName);
    data.append("mobile", formData.mobile);
    data.append("email", formData.email);
    data.append("official_mail", formData.official_mail);
    data.append("address", formData.address);
    data.append("desgination", formData.desgination);
    data.append("joining_date", formData.joiningDate);
    data.append("resign_date", "null");
  
    // Append image if available
    if (formData.image) {
      data.append("image", formData.image); // Appending the actual image file
    }
  
       const res = await postData3("api/v1/admin/register_emp/", data, true);
    if (res.status_code === 200) {
      handleUpdateEmployData();
    } else {
      toast.error(res.msg);
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const data = {
  //     gender: formData?.gender,
  //     dob: formData?.dob,
  //     id: userData?.user,
  //     emp_id: userData?.emp_id,
  //     first_name: formData?.firstName,
  //     last_name: formData?.lastName,
  //     mobile: formData?.mobile?.length > 11 ? userData?.mobile?.slice(3, 13) : userData?.mobile,
  //     email: formData?.email,
  //     official_mail: formData?.official_mail,
  //     address: formData?.address,
  //     desgination: formData?.desgination?.length > 20 ? formData?.desgination?.slice(0, 20) : formData?.desgination,
  //     joining_date: formData?.joiningDate,
  //     resign_date: "null",
  //   };

  //   const res = await postData1("api/v1/admin/register_emp/", data, true);
  //   if (res.status_code === 200) {
  //     handleUpdateEmployData();
  //   } else {
  //     toast.error(res.msg);
  //   }
  // };

  const handleDeleteUser = async () => {
    const data = {
      gender: userData?.gender,
      dob: userData?.dob,
      user: userData?.user,
      emp_id: userData?.emp_id,
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      mobile: userData?.mobile?.length > 11 ? userData?.mobile?.slice(3, 13) : userData?.mobile,
      email: userData?.email,
      official_mail: userData?.official_mail,
      address: userData?.address,
      desgination: userData?.desgination?.length > 20 ? userData?.desgination?.slice(0, 20) : userData?.desgination,
      joining_date: userData?.joining_date,
      is_deleted: isDeleted ? false : true,
    };

    const res = await postData1("api/v1/admin/register_emp/", data, true);
    if (res?.status_code === 200) {
      if (res?.is_deleted) {
        navigate("/admin/ex-employees");
      } else {
        navigate("/admin/employees");
      }
    } else {
      toast.error(res?.msg);
    }
  };

  return (
    <div className="xl:w-[80%] md:w-[85%] max-sm:w-[80%] sm:w m-auto p-6 bg-white border rounded-md shadow max-sm:mt-6">
      <h2 className="text-2xl text-orange-600 font-semibold sm:fixed">Update Employee</h2>

      <div className="lg:h-[70vh] overflow-auto no-scrollbar mt-12">
        <div className="flex flex-col md:flex-row gap-6">
          {/* Profile Section */}
          <div className="w-full md:w-1/3 flex flex-col items-center p-4 bg-gray-50 rounded-lg shadow">
            <div className="w-32 h-32 rounded-full overflow-hidden border-4 border-orange-500">
              <img
                src={profileImage ||profileImg  }
                alt="Profile"
                className="w-full h-full object-cover"
              />
            </div>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="mt-4 p-2 border rounded-md"
            />
            <h3 className="mt-4 text-xl font-semibold text-gray-700">
              {formData.firstName} {formData.lastName}
            </h3>
            <p className="text-sm text-gray-500">{formData.desgination}</p>
          </div>

          {/* Form Section */}
          <form
            onSubmit={handleSubmit}
            className="w-full md:w-2/3 grid grid-cols-1 md:grid-cols-2 gap-4"
          >
            {/* Form Fields */}
            <div className="flex flex-col mb-2">
              <label className="block text-base font-medium text-gray-600">First Name</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md outline-none"
                placeholder="First name"
                required
              />
            </div>
            <div className="flex flex-col mb-2">
              <label className="block text-base font-medium text-gray-600">Last Name</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md outline-none"
                placeholder="Last name"
                required
              />
            </div>
            <div className="flex flex-col mb-2">
              <label className="block text-base font-medium text-gray-600">Gender</label>
              <select
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md outline-none"
                required
              >
                <option value="M">Male</option>
                <option value="F">Female</option>
              </select>
            </div>
            <div className="flex flex-col mb-2">
              <label className="block text-base font-medium text-gray-600">Date of Birth</label>
              <input
                type="date"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md outline-none"
                required
              />
            </div>
            <div className="flex flex-col mb-2">
              <label className="block text-base font-medium text-gray-600">Mobile</label>
              <input
                type="text"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md outline-none"
                placeholder="Mobile no."
                required
              />
            </div>
            <div className="flex flex-col mb-2">
              <label className="block text-base font-medium text-gray-600">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md outline-none"
                placeholder="Email"
                required
              />
            </div>
            <div className="flex flex-col mb-2">
              <label className="block text-base font-medium text-gray-600">Official Email</label>
              <input
                type="email"
                name="official_mail"
                value={formData.official_mail}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md outline-none"
                placeholder="Official Email"
                required
              />
            </div>
            <div className="flex flex-col mb-2">
              <label className="block text-base font-medium text-gray-600">Joining Date</label>
              <input
                type="date"
                name="joiningDate"
                value={formData.joiningDate}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md outline-none"
                required
              />
            </div>
            <div className="flex flex-col mb-2">
              <label className="block text-base font-medium text-gray-600">Total Salary</label>
              <input
                type="number"
                name="totalSalary"
                value={formData.totalSalary}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md outline-none"
                placeholder="Total salary"
                required
              />
            </div>
            <div className="flex flex-col mb-2">
              <label className="block text-base font-medium text-gray-600">Department</label>
              <select
                name="department"
                value={formData.department}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md outline-none"
              >
                <option value="IT">IT</option>
                <option value="Operation">Operation</option>
                <option value="Accounts">Accounts</option>
                <option value="HR">HR</option>
                <option value="Academic Content Writer">Academic Content Writer</option>
                <option value="Graphics Designer">Graphics Designer</option>
                <option value="SEO">SEO</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="flex flex-col mb-2">
              <label className="block text-base font-medium text-gray-600">Designation</label>
              <input
                type="text"
                name="desgination"
                value={formData.desgination}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md outline-none"
                placeholder="Designation"
                required
              />
            </div>
            <div className="flex flex-col mb-2">
              <label className="block text-base font-medium text-gray-600">Employee Type</label>
              <select
                name="employeeType"
                value={formData.employeeType}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md outline-none"
              >
                <option value="FullTime">FullTime</option>
                <option value="PartTime">PartTime</option>
                <option value="Intern">Intern</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="flex flex-col mb-2">
              <label className="block text-base font-medium text-gray-600">Bank Name</label>
              <input
                type="text"
                name="bankName"
                value={formData.bankName}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md outline-none"
                placeholder="Bank Name"
                required
              />
            </div>
            <div className="flex flex-col mb-2">
              <label className="block text-base font-medium text-gray-600">Bank Account No.</label>
              <input
                type="number"
                name="bankAccount"
                value={formData.bankAccount}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md outline-none"
                placeholder="Bank Account No."
                required
              />
            </div>
            <div className="flex flex-col mb-2">
              <label className="block text-base font-medium text-gray-600">PF No.</label>
              <input
                type="text"
                name="pfNo"
                value={formData.pfNo}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md outline-none"
                placeholder="PF No."
                required
              />
            </div>
            <div className="flex flex-col mb-2">
              <label className="block text-base font-medium text-gray-600">PF Amount</label>
              <input
                type="number"
                name="pfAmount"
                value={formData.pfAmount}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md outline-none"
                placeholder="PF Amount"
                required
              />
            </div>
            <div className="flex flex-col mb-2">
              <label className="block text-base font-medium text-gray-600">ESI Amount</label>
              <input
                type="number"
                name="esiAmount"
                value={formData.esiAmount}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md outline-none"
                placeholder="ESI Amount"
                required
              />
            </div>
            <div className="flex flex-col mb-2">
              <label className="block text-base font-medium text-gray-600">Medical</label>
              <input
                type="number"
                name="medical"
                value={formData.medical}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md outline-none"
                placeholder="Medical"
                required
              />
            </div>
            <div className="flex flex-col mb-2">
              <label className="block text-base font-medium text-gray-600">Advance</label>
              <input
                type="number"
                name="advance"
                value={formData.advance}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md outline-none"
                placeholder="Advance"
                required
              />
            </div>
            <div className="flex flex-col mb-2">
              <label className="block text-base font-medium text-gray-600">Address</label>
              <textarea
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="mt-1 p-2 border rounded-md outline-none"
                placeholder="Address"
                rows="3"
                required
              ></textarea>
            </div>
            <div className="col-span-2 flex justify-center mt-4">
              <button
                type="submit"
                className="px-4 w-1/2 py-2 bg-orange-500 text-white font-semibold rounded-md hover:bg-orange-600 focus:outline-none"
              >
                Update
              </button>
            </div>
          </form>
        </div>
        <div className="mt-4 flex justify-center">
          <button
            type="button"
            onClick={handleDeleteUser}
            className="px-4 w-1/2 py-2 bg-red-600 text-white font-semibold rounded-md hover:bg-red-500 focus:outline-none"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};



// import React, { useEffect, useState } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import { postData1 } from "../../../APIs";
// import { toast } from "react-toastify";
// export const EditEmp = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   let userData = location.state.data;
//   let isDeleted = location.state.deleted;
//   const initailValue = {
//     firstName: userData?.first_name ? userData?.first_name : "",
//     lastName: userData?.last_name ? userData?.last_name : "",
//     gender: userData?.gender ? userData?.gender : "",
//     dob: userData?.dob ? userData?.dob : "",
//     mobile: userData?.mobile ? Number(userData?.mobile) : "0",
//     email: userData?.email ? userData?.email : "",
//     official_mail: userData?.email ? userData?.official_mail : "",
//     joiningDate: userData?.joining_date ? userData?.joining_date : "",
//     resignDate:
//       userData?.resign_date !== null && userData?.resign_date
//         ? userData?.resign_date
//         : "",
//     totalSalary: userData?.payroll?.total_salary
//       ? Number(userData?.payroll?.total_salary)
//       : "0",
//     department: userData?.payroll?.department
//       ? userData?.payroll?.department
//       : "",
//     desgination: userData?.desgination ? userData?.desgination : "",
//     employeeType: userData?.payroll?.emp_type
//       ? userData?.payroll?.emp_type
//       : "",
//     bankName: userData?.payroll?.bank_name
//       ? userData?.payroll?.bank_name
//       : "NA",
//     bankAccount: userData?.payroll?.bankac
//       ? Number(userData?.payroll?.bankac)
//       : "0",
//     pfNo: userData?.payroll?.pfno ? Number(userData?.payroll?.pfno) : "0",
//     pfAmount: userData?.payroll?.pf_amount
//       ? Number(userData?.payroll?.pf_amount)
//       : "0",
//     esiAmount: userData?.payroll?.esi_amount
//       ? Number(userData?.payroll?.esi_amount)
//       : "0",
//     basic: userData?.payroll?.basic ? Number(userData?.payroll?.basic) : "0",
//     DA: userData?.payroll?.DA ? Number(userData?.payroll?.DA) : "0",
//     HRA: userData?.payroll?.HRA ? Number(userData?.payroll?.HRA) : "0",
//     other: userData?.payroll?.other ? Number(userData?.payroll?.other) : "0",
//     medical: userData?.payroll?.medical
//       ? Number(userData?.payroll?.medical)
//       : "0",
//     advance: userData?.payroll?.advance
//       ? Number(userData?.payroll?.advance)
//       : "0",
//     address: userData?.address ? userData?.address : "",
//     resignDate: userData?.resign_date ? userData?.resign_date : "",
//   };
//   const [formData, setFormData] = useState(initailValue);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     if (value.trim() !== "") {
//       setFormData({ ...formData, [name]: value });
//     } else {
//       setFormData({ ...formData, [name]: "" });
//     }
//   };
//   const handleUpdateEmployData = async () => {
//     const data = {
//       id: userData?.user,
//       emp_id: userData?.emp_id,
//       total_salary: formData?.totalSalary
//         ? Number(formData?.totalSalary)
//         : userData?.payroll?.total_salary,
//       department: formData?.department
//         ? formData?.department
//         : userData?.payroll?.department,
//       emp_type: formData?.employeeType
//         ? formData?.employeeType
//         : userData?.payroll?.emp_type,
//       bank_name: formData?.bankName
//         ? formData?.bankName
//         : userData?.payroll?.bank_name,
//       bankac: formData?.bankAccount
//         ? Number(formData?.bankAccount)
//         : userData?.payroll?.bankac,
//       pfno: formData?.pfNo ? Number(formData?.pfNo) : userData?.payroll?.pfno,
//       pf_amount: formData?.pfAmount
//         ? Number(formData?.pfAmount)
//         : userData?.payroll?.pf_amount,
//       esi_amount: formData?.esiAmount
//         ? Number(formData?.esiAmount)
//         : userData?.payroll?.esi_amount,
//       other: formData?.other
//         ? Number(formData?.other)
//         : userData?.payroll?.other,
//       DA: formData?.DA ? Number(formData?.DA) : userData?.payroll?.DA,
//       HRA: formData?.HRA ? Number(formData?.HRA) : userData?.payroll?.HRA,
//       basic: formData?.basic
//         ? Number(formData?.basic)
//         : userData?.payroll?.basic,
//       advance: formData?.advance
//         ? Number(formData?.advance)
//         : userData?.payroll?.advance,
//       medical: formData?.medical
//         ? Number(formData?.medical)
//         : userData?.payroll?.medical,
//     };
//     const res = await postData1("api/v1/admin/register_payroll/", data, true);
//     if (res.status_code === 200) {
//       toast.success("Update SuccessFully");
//       setTimeout(() => {
//         navigate("/admin/employees");
//       }, 1000);
//     } else {
//       toast.error(res.msg ? res.msg : "something went wrong !");
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const data = {
//       gender: formData?.gender,
//       dob: formData?.dob,
//       id: userData?.user,
//       emp_id: userData?.emp_id,
//       first_name: formData?.firstName,
//       last_name: formData?.lastName,
//       mobile:
//         formData?.mobile?.length > 11
//           ? userData?.mobile?.slice(3, 13)
//           : userData?.mobile,
//       email: formData?.email,
//       official_mail: formData?.official_mail,
//       address: formData?.address,
//       desgination:
//         formData?.desgination?.length > 20
//           ? formData?.desgination?.slice(0, 20)
//           : formData?.desgination,
//       joining_date: formData?.joiningDate,
//       resign_date: "null",
//     };
//     const res = await postData1("api/v1/admin/register_emp/", data, true);
//     if (res.status_code === 200) {
//       handleUpdateEmployData();
//     } else {
//       toast.error(res.msg);
//     }
//   };

//   const handleDeleteUser = async () => {
//     let format = new Date().toLocaleDateString();
//     let resignDate = format?.split("/");
//     const data = {
//       gender: userData?.gender,
//       dob: userData?.dob,
//       user: userData?.user,
//       emp_id: userData?.emp_id,
//       first_name: userData?.first_name,
//       last_name: userData?.last_name,
//       mobile:
//         userData?.mobile?.length > 11
//           ? userData?.mobile?.slice(3, 13)
//           : userData?.mobile,
//       email: userData?.email,
//       official_mail: userData?.official_mail,
//       address: userData?.address,
//       desgination:
//         userData?.desgination?.length > 20
//           ? userData?.desgination?.slice(0, 20)
//           : userData?.desgination,
//       joining_date: userData?.joining_date,
//       is_deleted: isDeleted ? false : true,
//     };
//     const res = await postData1("api/v1/admin/register_emp/", data, true);
//     if (res?.status_code === 200) {
//       if (res?.is_deleted) {
//         navigate("/admin/ex-employees");
//       } else {
//         navigate("/admin/employees");
//       }
//     } else {
//       toast.error(res?.msg);
//     }
//   };

//   return (
//     <div className=" xl:w-[80%] md:w-[85%]  max-sm:w-[80%]  sm:w m-auto p-6 bg-white border rounded-md shadow max-sm:mt-6">
//       <h2 className="text-2xl text-orange-600 font-semibold  sm:fixed  ">
//         {" "}
//         Update Employee
//       </h2>

//       <div className="lg:h-[70vh] overflow-auto no-scrollbar mt-12">
//         <form
//           onSubmit={handleSubmit}
//           className="Forms grid  max-2xl:grid-cols-2 max-xl:grid-cols-2 max-lg:grid-cols-2 max-md:grid-cols-2 max-sm:grid-cols-1 gap-4 "
//         >
//           <div className="flex max-sm:flex-col max-sm:items-start mb-2 items-center">
//             <label className=" min-w-[140px]  block text-base font-medium text-gray-600">
//               First Name
//             </label>
//             <input
//               type="text"
//               name="firstName"
//               id="firstName"
//               value={formData.firstName}
//               onChange={handleChange}
//               className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//               placeholder="First name"
//               required
//             />
//           </div>
//           <div className="flex max-sm:flex-col max-sm:items-start mb-2 items-center">
//             <label className=" min-w-[140px]  block text-base font-medium text-gray-600">
//               Last Name
//             </label>
//             <input
//               type="text"
//               name="lastName"
//               id="lastName"
//               value={formData.lastName}
//               onChange={handleChange}
//               className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//               placeholder="Last name"
//               required
//             />
//           </div>

//           <div className="flex max-sm:flex-col max-sm:items-start mb-2 items-center">
//             <label className=" min-w-[140px]  block text-base font-medium text-gray-600">
//               Gender
//             </label>
//             <select
//               name="gender"
//               id="gender"
//               value={formData.gender}
//               onChange={handleChange}
//               className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//               placeholder="Gender"
//               required
//             >
//               <option value="M" selected={formData.gender === "M"}>
//                 Male
//               </option>
//               <option value="F" selected={formData.gender === "F"}>
//                 Female
//               </option>
//             </select>
//           </div>

//           <div className="flex max-sm:flex-col max-sm:items-start mb-2 items-center">
//             <label className=" min-w-[140px]  block text-base font-medium text-gray-600">
//               Date of Birth
//             </label>
//             <input
//               type="date"
//               name="dob"
//               id="dob"
//               value={formData.dob}
//               onChange={handleChange}
//               className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//               placeholder="D-O-B"
//               required
//             />
//           </div>

//           <div className="flex max-sm:flex-col max-sm:items-start mb-2 items-center">
//             <label className=" min-w-[140px]  block text-base font-medium text-gray-600">
//               Mobile
//             </label>
//             <input
//               type="text"
//               name="mobile"
//               id="mobile"
//               value={formData.mobile}
//               onChange={handleChange}
//               className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//               placeholder="Mobile no."
//               required
//             />
//           </div>

//           <div className="flex max-sm:flex-col max-sm:items-start mb-2 items-center">
//             <label className=" min-w-[140px]  block text-base font-medium text-gray-600">
//               Email
//             </label>
//             <input
//               type="email"
//               name="email"
//               id="email"
//               value={formData.email}
//               onChange={handleChange}
//               className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//               placeholder="Email"
//               required
//             />
//           </div>

//           <div className="flex max-sm:flex-col max-sm:items-start mb-2 items-center">
//             <label className=" min-w-[140px]  block text-base font-medium text-gray-600">
//               Official Email
//             </label>
//             <input
//               type="email"
//               name="official_mail"
//               id="official_mail"
//               value={formData.official_mail}
//               onChange={handleChange}
//               className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//               placeholder="Official Email"
//               required
//             />
//           </div>

//           <div className="flex max-sm:flex-col max-sm:items-start mb-2 items-center">
//             <label className=" min-w-[140px]  block text-base font-medium text-gray-600">
//               Joining Date
//             </label>
//             <input
//               type="date"
//               name="joiningDate"
//               id="joiningDate"
//               value={formData.joiningDate}
//               onChange={handleChange}
//               className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//               placeholder="Joining Date"
//               required
//             />
//           </div>

//           <div className="flex max-sm:flex-col max-sm:items-start mb-2 items-center">
//             <label className=" min-w-[140px]  block text-base font-medium text-gray-600">
//               Total Salary
//             </label>
//             <input
//               type="number"
//               name="totalSalary"
//               id="totalSalary"
//               value={formData.totalSalary}
//               onChange={handleChange}
//               className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//               placeholder="Total salary"
//               required
//             />
//           </div>
//           <div className="flex max-sm:flex-col max-sm:items-start mb-2 items-center">
//             <label className=" min-w-[140px]  block text-base font-medium text-gray-600">
//               Department
//             </label>
//             <select
//               name="department"
//               id="department"
//               value={
//                 formData.department !== ""
//                   ? formData.department
//                   : userData?.desgination
//               }
//               onChange={handleChange}
//               className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//             >
//               <option value="" disabled>
//                 Select Department
//               </option>
//               <option value="IT" selected={formData.department === "IT"}>
//                 IT
//               </option>
//               <option
//                 value="Operation"
//                 selected={formData.department === "Operation"}
//               >
//                 Operation
//               </option>
//               <option
//                 value="Accounts"
//                 selected={formData.department === "Accounts"}
//               >
//                 Accounts
//               </option>
//               <option value="HR" selected={formData.department === "HR"}>
//                 HR
//               </option>
//               <option
//                 value="Academic Content Writer"
//                 selected={formData.department === "Academic Content Writer"}
//               >
//                 Academic Content Writer
//               </option>
//               <option
//                 value="Graphics Designer"
//                 selected={formData.department === "Graphics Designer"}
//               >
//                 Graphics Designer
//               </option>
//               <option value="SEO" selected={formData.department === "SEO"}>
//                 SEO
//               </option>
//               <option value="Other" selected={formData.department === "Other"}>
//                 Other
//               </option>
//             </select>
//           </div>
//           <div className="flex max-sm:flex-col max-sm:items-start mb-2 items-center">
//             <label className=" min-w-[140px]  block text-base font-medium text-gray-600">
//               Desgination
//             </label>
//             <input
//               type="text"
//               name="desgination"
//               id="desgination"
//               value={formData.desgination}
//               onChange={handleChange}
//               className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//               placeholder="Advance Amount."
//               required
//             />
//           </div>

//           <div className="flex max-sm:flex-col max-sm:items-start mb-2 items-center">
//             <label className=" min-w-[140px]  block text-base font-medium text-gray-600">
//               Emp Type
//             </label>
//             <select
//               name="employeeType"
//               id="employeeType"
//               onChange={handleChange}
//               className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//             >
//               <option value="" disabled>
//                 Select Emp Type
//               </option>
//               <option
//                 value="FullTime"
//                 selected={formData?.employeeType === "FullTime"}
//               >
//                 FullTime
//               </option>
//               <option
//                 value="PartTime"
//                 selected={formData?.employeeType === "PartTime"}
//               >
//                 PartTime
//               </option>
//               <option
//                 value="Intern"
//                 selected={formData?.employeeType === "Intern"}
//               >
//                 Intern
//               </option>
//               <option
//                 value="Other"
//                 selected={formData?.employeeType === "Other"}
//               >
//                 Other
//               </option>
//             </select>
//           </div>

//           <div className="flex max-sm:flex-col max-sm:items-start mb-2 items-center">
//             <label className=" min-w-[140px]  block text-base font-medium text-gray-600">
//               Bank Name
//             </label>
//             <input
//               type="text"
//               name="bankName"
//               value={formData.bankName}
//               onChange={handleChange}
//               className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//               placeholder="Bankname"
//               required
//             />
//           </div>
//           <div className="flex max-sm:flex-col max-sm:items-start mb-2 items-center">
//             <label className=" min-w-[140px]  block text-base font-medium text-gray-600">
//               Bank Ac No.
//             </label>
//             <input
//               type="number"
//               name="bankAccount"
//               value={formData.bankAccount}
//               onChange={handleChange}
//               className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//               placeholder="Bank Account No."
//               required
//             />
//           </div>
//           <div className="flex max-sm:flex-col max-sm:items-start mb-2 items-center">
//             <label className=" min-w-[140px]  block text-base font-medium text-gray-600">
//               PF No.
//             </label>
//             <input
//               type="text"
//               name="pfNo"
//               value={formData.pfNo}
//               onChange={handleChange}
//               className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//               placeholder="Pf No."
//               required
//             />
//           </div>
//           <div className="flex max-sm:flex-col max-sm:items-start mb-2 items-center">
//             <label className=" min-w-[140px]  block text-base font-medium text-gray-600">
//               PF Amount
//             </label>
//             <input
//               type="number"
//               name="pfAmount"
//               value={formData.pfAmount}
//               onChange={handleChange}
//               className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//               placeholder="Pf Ammount."
//               required
//             />
//           </div>

//           <div className="flex max-sm:flex-col max-sm:items-start mb-2 items-center">
//             <label className=" min-w-[140px]  block text-base font-medium text-gray-600">
//               Esi Amount
//             </label>
//             <input
//               type="number"
//               name="esiAmount"
//               value={formData.esiAmount}
//               onChange={handleChange}
//               className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//               placeholder="Esi Amount."
//               required
//             />
//           </div>

//           <div className="flex max-sm:flex-col max-sm:items-start mb-2 items-center">
//             <label className=" min-w-[140px]  block text-base font-medium text-gray-600">
//               Medical
//             </label>
//             <input
//               type="number"
//               name="medical"
//               value={formData.medical}
//               onChange={handleChange}
//               className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//               placeholder="Medical."
//               required
//             />
//           </div>
//           <div className="flex max-sm:flex-col max-sm:items-start mb-2 items-center">
//             <label className=" min-w-[140px]  block text-base font-medium text-gray-600">
//               Advance
//             </label>
//             <input
//               type="number"
//               name="advance"
//               value={formData.advance}
//               onChange={handleChange}
//               className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//               placeholder="Advance Amount."
//               required
//             />
//           </div>

//           <div className="flex max-sm:flex-col max-sm:items-start mb-2 items-center">
//             <label className=" min-w-[140px]  block text-base font-medium text-gray-600">
//               Address
//             </label>
//             <textarea
//               name="address"
//               value={formData.address}
//               onChange={handleChange}
//               className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//               placeholder="First name"
//               rows="3"
//               required
//             ></textarea>
//           </div>

//           <div>
//             <div className=" flex  justify-center mt-4">
//               <button
//                 type="submit"
//                 className="px-4 w-1/2  py-2 bg-orange-500 text-white font-semibold rounded-md hover:bg-orange-600 focus:outline-none"
//               >
//                 Update
//               </button>
//             </div>
//             {/* <div className="mt-4 flex  justify-center mt-4">
//             <button
//               type="btn"
//               onClick={handleDeleteUser}
//               className="px-4 w-1/2  py-2 bg-red-600 text-white font-semibold rounded-md hover:bg-red-500 focus:outline-none"
//             >
//               Delete
//             </button>
//           </div> */}
//           </div>
//         </form>
//         <div className="mt-4 flex  justify-center ">
//           <button
//             type="btn"
//             onClick={handleDeleteUser}
//             className="px-4 w-1/2  py-2 bg-red-600 text-white font-semibold rounded-md hover:bg-red-500 focus:outline-none"
//           >
//             Delete
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };
