import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { postData1, postData3 } from "../../APIs";
import { toast } from "react-toastify";

export const RegistrationForm = () => {
  const location = useLocation();
  const userId = location?.state?.item;
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    designation: "",
    joiningDate: "",
    gender: "",
    dob: "",
    address: "",
    personalEmail: "",
    officialEmail: "",
    image: "", // New field for the image URL
  });

  const [imagePreview, setImagePreview] = useState(null); // To show the selected image preview

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value.trim() !== "") {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: "" });
    }
  };
  
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result); // Set preview URL for the image
      };
      reader.readAsDataURL(file);
  
      // Update the formData with the actual file object
      setFormData({ ...formData, image: file });
    }
  };
  
  

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const data = {
  //     gender: formData.gender,
  //     dob: formData.dob,
  //     user: userId,
  //     first_name: formData.firstName,
  //     last_name: formData.lastName,
  //     mobile: formData.mobile,
  //     email: formData.personalEmail,
  //     official_mail: formData.officialEmail,
  //     address: formData.address,
  //     desgination: formData.designation,
  //     joining_date: formData.joiningDate,
  //     resign_date: "null",
  //     image: formData.image, // Send image name
  //   };
  //   console.log(data)

  //   const res = await postData3("api/v1/admin/register_emp/", data, true);
  //   if (res?.status_code === 200) {
  //     toast.success("Employee Registered Successfully !");
  //     // setFormData({
  //     //   firstName: "",
  //     //   lastName: "",
  //     //   mobile: "",
  //     //   designation: "",
  //     //   joiningDate: "",
  //     //   gender: "",
  //     //   dob: "",
  //     //   address: "",
  //     //   personalEmail: "",
  //     //   officialEmail: "",
  //     //   image: "",
  //     // });
  //     // setImagePreview(null);
  //     // setTimeout(() => {
  //     //   navigate("payroll", { state: { item: res?.data } });
  //     // }, 2000);
  //   } else {
  //     toast.error(
  //       res?.msg?.mobile ? res?.msg?.mobile[0] : "Something went wrong!"
  //     );
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const data = new FormData();
    data.append("gender", formData.gender);
    data.append("dob", formData.dob);
    data.append("user", userId);
    data.append("first_name", formData.firstName);
    data.append("last_name", formData.lastName);
    data.append("mobile", formData.mobile);
    data.append("email", formData.personalEmail);
    data.append("official_mail", formData.officialEmail);
    data.append("address", formData.address);
    data.append("desgination", formData.designation);
    data.append("joining_date", formData.joiningDate);
    data.append("resign_date", "null");
  
    // Append image if available
    if (formData.image) {
      data.append("image", formData.image); // Appending the actual image file
    }
  
    try {
      const res = await postData3("api/v1/admin/register_emp/", data, true);
      if (res?.status_code === 200) {
        toast.success("Employee Registered Successfully !");
        // Optionally reset form data after successful submission
        setFormData({
          firstName: "",
          lastName: "",
          mobile: "",
          designation: "",
          joiningDate: "",
          gender: "",
          dob: "",
          address: "",
          personalEmail: "",
          officialEmail: "",
          image: "",
        });
        setImagePreview(null);
        navigate("payroll", { state: { item: res?.data } });
      } else {
        toast.error(res?.msg?.mobile ? res?.msg?.mobile[0] : "Something went wrong!");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      console.error(error);
    }
  };
  return (
    <div className="xl:w-[90%] md:w-[85%] max-sm:w-[80%] sm:w m-auto p-6 bg-white border rounded-md shadow">
      <h2 className="text-2xl text-orange-600 font-semibold mb-4">
        Employee Registration
      </h2>

      <form
        onSubmit={handleSubmit}
        className="Forms grid max-2xl:grid-cols-2 max-xl:grid-cols-2 max-lg:grid-cols-2 max-md:grid-cols-2 max-sm:grid-cols-1 gap-4"
      >
        
<div className="flex flex-col items-center mb-4">
  <label className="block text-lg font-medium text-gray-600 mb-2">
    Profile Picture
  </label>
  <div className="relative w-32 h-32">
    {/* Hidden file input */}
    <input
      type="file"
      accept="image/*"
      onChange={handleImageChange}
      className="hidden"
      id="profile-picture-input"
    />
    {/* Clickable image preview */}
    <label
      htmlFor="profile-picture-input"
      className="cursor-pointer w-full h-full"
    >
      {imagePreview ? (
        <img
          src={imagePreview}
          alt="Preview"
          className="w-32 h-32 object-cover rounded-full border-2 border-gray-300"
        />
      ) : (
        <div className="w-32 h-32 rounded-full border-2 border-dashed border-gray-300 flex items-center justify-center text-gray-500">
          Upload
        </div>
      )}
    </label>
  </div>
</div>



        {/* Other Form Fields */}
    
        <div className="flex max-sm:flex-col max-sm:items-start mb-4 items-center">
          <label className="min-w-[140px] block text-lg font-medium text-gray-600">
            First Name
          </label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
            placeholder="First name"
            required
          />
        </div>
        {/* Add all other fields similar to this */}
      
       
        <div className="flex max-sm:flex-col max-sm:items-start mb-4 items-center">
          <label className=" min-w-[140px]  block text-lg font-medium text-gray-600">
            Last Name
          </label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
            placeholder="Last name"
            required
          />
        </div>
        <div className="flex max-sm:flex-col max-sm:items-start mb-4 items-center">
          <label className=" min-w-[140px]  block text-lg font-medium text-gray-600">
            Email (Personal)
          </label>
          <input
            type="email"
            name="personalEmail"
            value={formData.personalEmail}
            onChange={handleChange}
            className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
            placeholder="Personal Email"
            required
          />
        </div>
        <div className="flex max-sm:flex-col max-sm:items-start mb-4 items-center">
          <label className=" min-w-[140px]  block text-lg font-medium text-gray-600">
            Email (Official)
          </label>
          <input
            type="email"
            name="officialEmail"
            value={formData.officialEmail}
            onChange={handleChange}
            className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
            placeholder="Official Email"
            required
          />
        </div>
        <div className="flex max-sm:flex-col max-sm:items-start mb-4 items-center">
          <label className=" min-w-[140px]  block text-lg font-medium text-gray-600">
            Mobile
          </label>
          <input
            type="text"
            name="mobile"
            value={formData.mobile}
            onKeyDown={blockInvalidChar}
            onChange={handleChange}
            className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
            placeholder="Mobile no."
            required
          />
        </div>
        <div className="flex max-sm:flex-col max-sm:items-start mb-4 items-center">
          <label className=" min-w-[140px]  block text-lg font-medium text-gray-600">
            Designation
          </label>
          <input
            type="text"
            name="designation"
            value={formData.designation}
            onChange={handleChange}
            className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
            placeholder="Designation"
            required
          />
        </div>
        <div className="flex max-sm:flex-col max-sm:items-start mb-4 items-center">
          <label className=" min-w-[140px]  block text-lg font-medium text-gray-600">
            Joining Date
          </label>
          <input
            type="date"
            name="joiningDate"
            value={formData.joiningDate}
            onChange={handleChange}
            className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
            placeholder="Joining Date"
            required
          />
        </div>
        <div className="flex max-sm:flex-col max-sm:items-start mb-4 items-center">
          <label className=" min-w-[140px]  block text-lg font-medium text-gray-600">
            Gender
          </label>
          <select
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
            placeholder="Gender"
            required
          >
            <option value="" selected>
              Select Gender
            </option>
            <option value="M">Male</option>
            <option value="F">Female</option>
          </select>
        </div>
        <div className="flex max-sm:flex-col max-sm:items-start mb-4 items-center">
          <label className=" min-w-[140px]  block text-lg font-medium text-gray-600">
            Date of Birth
          </label>
          <input
            type="date"
            name="dob"
            value={formData.dob}
            onChange={handleChange}
            className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
            placeholder="D-O-B"
            required
          />
        </div>
        <div className="flex max-sm:flex-col max-sm:items-start mb-4 items-center">
          <label className=" min-w-[140px]  block text-lg font-medium text-gray-600">
            Address
          </label>
          <textarea
            name="address"
            value={formData.address}
            onChange={handleChange}
            className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
            placeholder="full address"
            rows="3"
            required
          ></textarea>
        </div>

      {/* --------------------------- */}

        <div className="mt-4 flex justify-center">
          <button
            type="submit"
            className="px-4 w-[200px] py-2 bg-orange-500 text-white font-semibold rounded-md hover:bg-orange-600 focus:outline-none"
          >
            Register
          </button>
        </div>
      </form>
    </div>
  );
};

export default RegistrationForm;



// import React, { useState } from "react";
// import {  useLocation, useNavigate } from "react-router-dom";
// import { postData1 } from "../../APIs";
// import { toast } from "react-toastify";
// export const RegistrationForm = () => {
//   const location = useLocation();
//   const userId = location?.state?.item;
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     firstName: "",
//     lastName: "",
//     mobile: "",
//     designation: "",
//     joiningDate: "",
//     gender: "",
//     dob: "",
//     address: "",
//     personalEmail: "",
//     officialEmail:""
//   });

//   const blockInvalidChar = (e) =>
//     ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     if (value.trim() !== "") {
//       setFormData({ ...formData, [name]: value });
//     } else {
//       setFormData({ ...formData, [name]: "" });
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const data = {
//       gender: formData.gender,
//       dob: formData.dob,
//       user: userId,
//       first_name: formData.firstName,
//       last_name: formData.lastName,
//       mobile: formData.mobile,
//       email: formData.personalEmail,
//       official_mail: formData.officialEmail,
//       address: formData.address,
//       desgination: formData.designation,
//       joining_date: formData.joiningDate,
//       resign_date: "null",
//     };
//     const res = await postData1("api/v1/admin/register_emp/", data, true);
//     if (res?.status_code === 200) {
//       toast.success("Employee Registered Successfully !");
//       setFormData({
//         firstName: "",
//         lastName: "",
//         mobile: "",
//         designation: "",
//         joiningDate: "",
//         gender: "",
//         dob: "",
//         address: "",
//         personalEmail: "",
//         officialEmail:""
//       });
//       setTimeout(() => {
//         navigate("payroll", { state: { item: res?.data } });
//       }, 2000);
//     } else {
//       toast.error(
//         res?.msg?.mobile ? res?.msg?.mobile[0] : "something went wrong !"
//       );
//     }
//   };

//   return (
//     <div className=" xl:w-[90%] md:w-[85%]  max-sm:w-[80%] sm:w m-auto p-6 bg-white border rounded-md shadow">
//       <h2 className="text-2xl text-orange-600 font-semibold mb-4">
//         Employee Registration
//       </h2>

//       <form
//         onSubmit={handleSubmit}
//         className="Forms grid  max-2xl:grid-cols-2 max-xl:grid-cols-2 max-lg:grid-cols-2 max-md:grid-cols-2 max-sm:grid-cols-1 gap-4 "
//       >
//         <div className="flex max-sm:flex-col max-sm:items-start mb-4 items-center">
//           <label className=" min-w-[140px]  block text-lg font-medium text-gray-600">
//             First Name
//           </label>
//           <input
//             type="text"
//             name="firstName"
//             value={formData.firstName}
//             onChange={handleChange}
//             className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//             placeholder="First name"
//             required
//           />
//         </div>
//         <div className="flex max-sm:flex-col max-sm:items-start mb-4 items-center">
//           <label className=" min-w-[140px]  block text-lg font-medium text-gray-600">
//             Last Name
//           </label>
//           <input
//             type="text"
//             name="lastName"
//             value={formData.lastName}
//             onChange={handleChange}
//             className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//             placeholder="Last name"
//             required
//           />
//         </div>
//         <div className="flex max-sm:flex-col max-sm:items-start mb-4 items-center">
//           <label className=" min-w-[140px]  block text-lg font-medium text-gray-600">
//             Email (Personal)
//           </label>
//           <input
//             type="email"
//             name="personalEmail"
//             value={formData.personalEmail}
//             onChange={handleChange}
//             className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//             placeholder="Email"
//             required
//           />
//         </div>
//         <div className="flex max-sm:flex-col max-sm:items-start mb-4 items-center">
//           <label className=" min-w-[140px]  block text-lg font-medium text-gray-600">
//             Email (Official)
//           </label>
//           <input
//             type="email"
//             name="official_mail"
//             value={formData.officialEmail}
//             onChange={handleChange}
//             className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//             placeholder="Email"
//             required
//           />
//         </div>
//         <div className="flex max-sm:flex-col max-sm:items-start mb-4 items-center">
//           <label className=" min-w-[140px]  block text-lg font-medium text-gray-600">
//             Mobile
//           </label>
//           <input
//             type="text"
//             name="mobile"
//             value={formData.mobile}
//             onKeyDown={blockInvalidChar}
//             onChange={handleChange}
//             className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//             placeholder="Mobile no."
//             required
//           />
//         </div>
//         <div className="flex max-sm:flex-col max-sm:items-start mb-4 items-center">
//           <label className=" min-w-[140px]  block text-lg font-medium text-gray-600">
//             Designation
//           </label>
//           <input
//             type="text"
//             name="designation"
//             value={formData.designation}
//             onChange={handleChange}
//             className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//             placeholder="Designation"
//             required
//           />
//         </div>
//         <div className="flex max-sm:flex-col max-sm:items-start mb-4 items-center">
//           <label className=" min-w-[140px]  block text-lg font-medium text-gray-600">
//             Joining Date
//           </label>
//           <input
//             type="date"
//             name="joiningDate"
//             value={formData.joiningDate}
//             onChange={handleChange}
//             className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//             placeholder="Joining Date"
//             required
//           />
//         </div>
//         <div className="flex max-sm:flex-col max-sm:items-start mb-4 items-center">
//           <label className=" min-w-[140px]  block text-lg font-medium text-gray-600">
//             Gender
//           </label>
//           <select
//             name="gender"
//             value={formData.gender}
//             onChange={handleChange}
//             className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//             placeholder="Gender"
//             required
//           >
//             <option value="" selected>
//               Select Gender
//             </option>
//             <option value="M">Male</option>
//             <option value="F">Female</option>
//           </select>
//         </div>
//         <div className="flex max-sm:flex-col max-sm:items-start mb-4 items-center">
//           <label className=" min-w-[140px]  block text-lg font-medium text-gray-600">
//             Date of Birth
//           </label>
//           <input
//             type="date"
//             name="dob"
//             value={formData.dob}
//             onChange={handleChange}
//             className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//             placeholder="D-O-B"
//             required
//           />
//         </div>
//         <div className="flex max-sm:flex-col max-sm:items-start mb-4 items-center">
//           <label className=" min-w-[140px]  block text-lg font-medium text-gray-600">
//             Address
//           </label>
//           <textarea
//             name="address"
//             value={formData.address}
//             onChange={handleChange}
//             className="mt-1 p-1 px-2 w-full border rounded-md outline-none"
//             placeholder="full address"
//             rows="3"
//             required
//           ></textarea>
//         </div>
//         <div className="mt-4 flex  justify-center ">
//           <button
//             type="submit"
//             className="px-4 w-[200px]  py-2 bg-orange-500 text-white font-semibold rounded-md hover:bg-orange-600 focus:outline-none"
//           >
//             Register
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };
