import React from 'react';
import html2pdf from 'html2pdf.js';
import numberToWords from 'number-to-words';
import { useLocation } from 'react-router-dom';

const Logo = process.env.PUBLIC_URL + "/images/logdo.png";

export const InvoicePdf = () => {
  const location = useLocation();
  const { payrollData, userData } = location.state || {};
  const empData = payrollData;

  const handleConvertToPdf = () => {
    const element = document.getElementById('content-to-convert');

    if (element) {
      const pdfOptions = {
        margin: 10,
        filename: 'payslip.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };

      html2pdf(element, pdfOptions);
    }
  };

  const totalSalary = empData?.net_salary;
  const salaryInWords = totalSalary ? numberToWords.toWords(totalSalary) : 'Not available';

  const capitalizeFirstLetter = (str) => {
    return str.replace(/\b\w/g, char => char.toUpperCase());
  };

  const result = capitalizeFirstLetter(salaryInWords);

  return (
    <div className='w-[90%] min-h-[720px] m-auto bg-white p-5'>
      <div id="content-to-convert" className='w-full font-sans'>
        {/* Header Section */}
        <div className='flex justify-between items-center mb-5'>
          <img src={Logo} alt='Company Logo' className='w-[230px]' />
          <div className='text-center'>
            <h1 className='text-2xl font-bold'>GET YOUR PROJECT READY PRIVATE LIMITED</h1>
            <p className='w-[330px] m-auto text-sm'>
              New Delhi, Panchsheel Park, Malviya Nagar, 5th Floor B-99, Triveni Complex, 110017.
            </p>
          </div>
        </div>

        {/* Payslip Title */}
        <h2 className='mt-10 pb-2 text-xl font-bold'>
          Payslip For the Month of {empData?.month ? capitalizeFirstLetter(empData?.month) : ''} {empData?.year}.
        </h2>
        <hr className='mb-5' />

        {/* Employee Details */}
        <div className='flex justify-between mb-5'>
          <div>
            <div><span>Emp No: </span><span>GYPR-{userData?.emp_id}</span></div>
            <div><span>Name: </span><span>{userData?.first_name} {userData?.last_name}</span></div>
            <div><span>Paid Days: </span><span>30</span></div>
          </div>

          <div>
            <div><span>Bank Name: </span><span>{empData?.bank_name}</span></div>
            <div><span>Bank A/C No: </span><span>{empData?.bankac}</span></div>
            <div><span>Leaves: </span><span>{empData?.accepted_leaves}</span></div>
          </div>

          <div>
            <div><span>Department: </span><span>{empData?.department}</span></div>
            <div><span>PF No.: </span><span>{empData?.pfno}</span></div>
          </div>
        </div>
        <hr className='mb-5' />

        {/* Earnings and Deductions */}
        <div className='flex justify-between mb-5'>
          <div className='w-[25%]'>
            <h1 className='font-bold mb-2'>EARNINGS</h1>
            <div>Basic</div>
            <div>Dearness Allowance</div>
            <div>House Rent Allowance</div>
            <div>Other Allowances</div>
            <div>All Cash Reimbursements</div>
            <div>LTA*</div>
            <div>Medical*</div>
            <div>Arrears</div>
          </div>

          <div className='w-[15%] text-right'>
            <h1 className='font-bold mb-2'>PER MONTH</h1>
            <div>{empData?.basic || 0}</div>
            <div>{empData?.DA || 0}</div>
            <div>{empData?.HRA || 0}</div>
            <div>{empData?.other || 0}</div>
            <div>{empData?.cash_reimbursements || 0}</div>
            <div>{empData?.LTA || 0}</div>
            <div>{empData?.medical || 0}</div>
            <div>{empData?.Arrars || 0}</div>
          </div>

          <div className='w-[25%]'>
            <h1 className='font-bold mb-2'>DEDUCTIONS</h1>
            <div>PF</div>
            <div>ESI</div>
            <div>Income Tax*</div>
            <div>Professional Tax</div>
            <div>Loans & Advances</div>
            <div>Absent</div>
          </div>

          <div className='w-[15%] text-right'>
            <h1 className='font-bold mb-2'>PER MONTH</h1>
            <div>{empData?.pf_amount || 0}</div>
            <div>{empData?.esi_amount || 0}</div>
            <div>{empData?.income_tax || 0}</div>
            <div>{empData?.prof_tax || 0}</div>
            <div>{empData?.advance || 0}</div>
            <div>{empData?.absent_attendance || 0}</div>
          </div>
        </div>
        <hr className='mb-5' />

        {/* Net Salary Section */}
        <div className='flex justify-between mb-10'>
          <div className='w-[30%]'>
            <h1 className='font-bold mb-2'>Gross Earnings (A)</h1>
            <h1 className='font-bold mb-2'>Net Salary Payable (A-B)</h1>
            <h1 className='font-bold mb-2'>Net Salary Payable (in words)</h1>
          </div>

          <div className='w-[20%] text-right'>
            <h1 className='font-bold mb-2'>{empData?.gross_earning || 0}</h1>
            <h1 className='font-bold mb-2'>{empData?.net_salary || 0}</h1>
            <h1 className='font-bold mb-2'>{result || "zero"}</h1>
          </div>

          <div className='w-[30%] ml-10'>
            <h1 className='font-bold mb-2'>Gross Deductions (B)</h1>
          </div>

          <div className='w-[20%] text-right'>
            <h1 className='font-bold mb-2'>{empData?.gross_deduction || 0}</h1>
          </div>
        </div>
        <hr className='mb-5' />

        {/* Signature Section */}
        <div className='mt-20 mb-10'>
          <p>Signature</p>
        </div>
      </div>

      {/* Download Button */}
      <div className='text-right mt-5'>
        <button
          className='bg-orange-500 px-4 py-2 text-white rounded hover:bg-orange-600'
          onClick={handleConvertToPdf}
        >
          Download
        </button>
      </div>
    </div>
  );
};


// import React from 'react';
// import html2pdf from 'html2pdf.js';
// import numberToWords from 'number-to-words';
// import { useLocation } from 'react-router-dom';
// const Logo=process.env.PUBLIC_URL+"/images/logdo.png"

// // const months = [
// //   "January", "February", "March", "April",
// //   "May", "June", "July", "August",
// //   "September", "October", "November", "December"
// // ];

// export const InvoicePdf = () => {
// const location = useLocation();
// const { payrollData, userData } = location.state || {};
//  const empData = payrollData;

//   // const currDate=new Date();

//   const handleConvertToPdf = () => {
//     const element = document.getElementById('content-to-convert');
    
//     if (element) {
//       const pdfOptions = {
//         margin: 10,
//         filename: 'converted-document.pdf',
//         image: { type: 'jpeg', quality: 0.98 },
//         html2canvas: { scale: 2 },
//         jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
//       };

//       html2pdf(element, pdfOptions);
//     }
//   };

  
//   const totalSalary = empData?.net_salary;

//   const salaryInWords = totalSalary
//     ? numberToWords.toWords(totalSalary)
//     : 'Not available';
//     function capitalizeFirstLetter(str) {
//       return str.replace(/\b\w/g, char => char.toUpperCase());
//     }
    
    
//     const result = capitalizeFirstLetter(salaryInWords);
//   return (
//     <div className='w-[90%] h-[730px] m-auto bg-white p-5 overflow-hidden overflow-y-scroll'>
//       <div id="content-to-convert">
//         <div className='flex justify-between items-center'>
//           <img src={Logo} alt='loading...' width="230px" />
//           <div className='text-center'>
//             <h1 className='text-xl font-bold'>GET YOUR PROJECT READY PRIVATE LIMITED</h1>
//             <p className='w-[330px] m-auto text-sm'>New Delhi , Panchsheel Park , Malviya Nagar , 5th Floor B-99 , Triveni
//                 Complex , 110017.</p>
//           </div>
//         </div>
//         {/* <h2 className='mt-20 pb-4 '>Payslip For the Month of {months[currDate.getMonth()]} {currDate.getFullYear()}.</h2><hr/> */}
//         <h2 className='mt-20 pb-4 '>
//           Payslip For the Month of {empData?.month ? capitalizeFirstLetter(empData?.month) : ''} {empData?.year}.
//         </h2>
//         <hr/>

//         <div className='flex flex-wrap justify-between pt-5'>
//           <div>
//             <div><span>Emp No : </span><span>GYPR-{userData?.emp_id}</span></div>
//             <div><span>Name : </span><span>{userData?.first_name} {userData?.last_name}</span></div>
//             <div><span>Paid Days : </span><span>30</span></div>
//           </div>

//           <div>
//             <div><span>Bank Name : </span><span>{empData?.bank_name}</span></div>
//             <div><span>Bank A/C No : </span><span>{empData?.bankac}</span></div>
//             <div><span>Leaves : </span><span>{empData?.accepted_leaves}</span></div>
//           </div>

//           <div>
//             <div><span>Department : </span><span>{empData?.department}</span></div>
//             <div><span>PF No. : </span><span>{empData?.pfno}</span></div>
//           </div>
//         </div>
//         <hr className='mt-10'/>
//         <div className='flex flex-wrap justify-between pt-5 '>
//           <div className='flex flex-col'>
//             <h1 className='font-bold mb-2'>EARNINGS</h1>
//             <span>Basic</span>
//             <span>Dearness Allowance</span>
//             <span>House Rent Allowance</span>
//             <span>Other Allowances</span>
//             <span>All Cash Reimbursements</span>
//             <span>LTA*</span>
//             <span>Medical*</span>
//             <span>Arrears</span>
//           </div>

//           <div className='flex flex-col'>
//             <h1 className='font-bold mb-2'>PER MONTH</h1>
//             <span>{empData?.basic || 0}</span>
//             <span>{empData?.DA || 0}</span>
//             <span>{empData?.HRA || 0}</span>
//             <span>{empData?.other || 0}</span>
//             <span>{empData?.cash_reimbursements || 0}</span>
//             <span>{empData?.LTA || 0}</span>
//             <span>{empData?.medical || 0}</span>
//             <span>{empData?.Arrars || 0}</span>
            
//           </div>

//           <div className='flex flex-col'>
//             <h1 className='font-bold mb-2'>DEDUCTIONS</h1>
//             <span>PF</span>
//             <span>ESI</span>
//             <span>Income Tax*</span>
//             <span>Professional Tax</span>
//             <span>Loans & Advances</span>
//             <span>Absent</span>
//           </div>

//           <div className='flex flex-col'>
//             <h1 className='font-bold mb-2'>PER MONTH</h1>
//             <span>{empData?.pf_amount || 0}</span>
//             <span>{empData?.esi_amount || 0}</span>
//             <span>{empData?.income_tax || 0}</span>
//             <span>{empData?.prof_tax || 0}</span>
//             <span>{empData?.advance || 0}</span>
//             <span>{empData?.absent_attendance || 0}</span>
//           </div>

//         </div>

//         <hr className='mt-10'/>
//         <div className='flex flex-wrap justify-between pt-5'>
//           <div className='flex flex-col'>
//             <h1 className='font-bold mb-2'>Gross Earnings (A)</h1>
//             <h1 className='font-bold mb-2'>Net Salary Payable (A-B)</h1>
//             <h1 className='font-bold mb-2'>Net Salary Payable(in words)</h1>
//           </div>

//           <div className='flex flex-col'>
//             <h1 className='font-bold mb-2'>{empData?.gross_earning || 0}</h1>
//             <h1 className='font-bold mb-2'>{empData?.net_salary ||0}</h1>
//             <h1 className='font-bold mb-2'>{result || "zero"}</h1>
//           </div>

//           <div className='flex flex-col'>
//             <h1 className='font-bold mb-2'>Gross Deductions (B)</h1>
//           </div>

//           <div className='flex flex-col'>
//             <h1 className='font-bold mb-2'>{empData?.gross_deduction || 0}</h1>
//           </div>
//         </div>

//         <hr className='mt-10 mb-20'/>
//         <p>Singnature</p><br/>

//       </div>


//       <div className='text-right'>
//         <button
//         className='bg-orange-500 p-2 text-white '
//         onClick={handleConvertToPdf}
//         >Download</button>

//       </div>
//     </div>
//   );
// };




