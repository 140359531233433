import React from "react";
import ConsultancyCharts from "./ConsultancyCharts";

const ConsultancyDashboard = () => {
  return (
    <>
      <ConsultancyCharts />
    </>
  );
};

export default ConsultancyDashboard;
