// import axios from 'axios'
const BaseURL = process.env.REACT_APP_BASEURL;

const postData = async (url, formData, isAuthenticated, isAdmin) => {
  const headers = {
    // "Content-Type": "application/json",
    Accept: "application/json",
  };

  if (isAuthenticated) {
    const token = localStorage.getItem(isAdmin ? "adminAuth" : "token");
    headers.Authorization = `Token ${token}`;
  }
  const response = await fetch(`${BaseURL}/${url}`, {
    mode: "cors",
    method: "POST",
    headers: headers,
    body: formData,
  });
  try {
    const result1 = await response.json();
    return result1;
  } catch (e) {
    console.error(e, "error");
  }
};
const postData1 = async (url, body, isAuthenticated, isAdmin) => {
  const headers = {
    "Content-Type": "application/json; charset=UTF-8",
    Accept: "application/json",
  };

  if (isAuthenticated) {
    const token = localStorage.getItem(isAdmin ? "adminAuth" : "token");
    headers.Authorization = `Token ${token}`; 
  }
  const response = await fetch(`${BaseURL}/${url}`, {
    mode: "cors",
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  });
  try {
    const result1 = await response.json();
    return result1;
  } catch (e) {
    console.error(e, "error");
  }
};

const postData3 = async (url, body, isAuthenticated, isAdmin) => {
  const headers = {
    Accept: "application/json",
  };

  if (isAuthenticated) {
    const token = localStorage.getItem(isAdmin ? "adminAuth" : "token");
    headers.Authorization = `Token ${token}`;
  }

  try {
    const response = await fetch(`${BaseURL}/${url}`, {
      method: "POST",
      headers: headers,
      body: body, // Pass the FormData directly as the body
    });

    const result1 = await response.json();
    return result1;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};


const postData2 = async (url, body, isAuthenticated, isAdmin) => {
  const headers = {
    "Content-Type": "application/json; charset=UTF-8",
    Accept: "application/json",
  };

  if (isAuthenticated) {
    const token = localStorage.getItem(isAdmin ? "adminAuth" : "token");
    headers.Authorization = `Token ${token}`; 
  }
  const response = await fetch(`${BaseURL}/${url}`, {
    mode: "cors",
    method: "PATCH",
    headers: headers,
    body: JSON.stringify(body),
  });
  try {
    const result1 = await response.json();
    return result1;
  } catch (e) {
    console.error(e, "error");
  }
};


const addEmployeeLeaves = async (body) => {
  const headers = {
    "Content-Type": "application/json; charset=UTF-8",
    Accept: "application/json",
  };
  const token = localStorage.getItem("token");
  headers.Authorization = `Token ${token}`;
  const response = await fetch(`${BaseURL}/api/v1/admin/add_emp_leaves/`, {
    mode: "cors",
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  });
  try {
    const result1 = await response.json();
    return result1;
  } catch (e) {
    console.error(e, "error");
  }
};


const getEmployeeLeavesDetails = async () => {
  const headers = {
    Authorization: `Token ${localStorage.getItem("token")}`,
  };
  const response = await fetch(`${BaseURL}/api/v1/get_emp_leaves/`, {
    mode: "cors",
    method: "GET",
    headers: headers,
  });
  try {
    const result1 = await response.json();
    // console.log("leaves", result1)
    return result1;
  } catch (e) {
    console.error(e, "error");
  }
};

const getEmployeeYearPayRoll = async (year) => {
  
  const headers = {
    Authorization: `token ${localStorage.getItem("token")}`, 
    "Content-Type": "application/json",
  };
  try {
    const response = await fetch(`${BaseURL}/api/v1/get_payroll_year/`, {
      method: "POST", 
      headers: headers,
      body: JSON.stringify({ year: Number(year) }), 
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result1 = await response.json();
    // console.log("API response:", result1); 
    return result1?.data;
  } catch (e) {
    console.error("Error in getEmployeeYearPayRoll:", e); 
    throw e; // Re-throw the error to be caught by the thunk
  }
};


// add this api to call when emplyoee loggin 
const fetchAttendance = async () => {
  try {
    await fetch(`${BaseURL}/api/v1/view_attendence/`, {
      method: 'POST',
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        'Content-Type': 'application/json',
      },
    });
    // const data = await res.json();
    
  } catch (error) {
    console.error('Error fetching attendance:', error);
  }
};


export { postData, postData1, postData2,addEmployeeLeaves,getEmployeeLeavesDetails,getEmployeeYearPayRoll, postData3,fetchAttendance };
