import React from "react";
import * as XLSX from "xlsx";

const DownloadLeaveFormate = () => {
  const handleDownload = () => {
    // Create sample data
    const data = [
      [
        "id",
        "CL",
        "SL",
        "PL",
      ],
      [
        "3",
        20,
        90,
        45    
      ],
     
      // Add more rows as needed
    ];
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "employee_test_data.xlsx");
  };

  return (
    <div>
      <button onClick={handleDownload}>Download</button>
    </div>
  );
};

export default DownloadLeaveFormate;
