import React, { useState, useEffect } from "react";
import { UserProfile } from "../components/UserProfile";
import { NavLink } from "react-router-dom";
import { fetchEmployeePayRoll } from "../../Redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

export const SalarySlip = () => {
  const dispatch = useDispatch();
  const { payroll,userData } = useSelector((state) => state.user); 
  const [year, setYear] = useState(""); 
  const [selectedYear, setSelectedYear] = useState(null); 

  const handleSubmit =async (e) => {
    e.preventDefault();

    if (year) {
      const newYear = Number(year);
      setSelectedYear(newYear); 
      if (!payroll || payroll[0]?.year !== newYear) {
        // Dispatch API call only if data is not already loaded for the selected year
      await  dispatch(fetchEmployeePayRoll({ year: newYear }));
      }
    } else {
      toast.warn("Please select a year");
    }
  };
  
  return (
    <>
      {/* User Profile */}
      <div className="">
        <UserProfile />
      </div>

      {/* Year Selection Form */}
      <div className="flex justify-center items-start text-center mt-10">
        <div className="w-[700px] bg-white rounded shadow py-5">
          <div className="flex items-start justify-center">
            <form onSubmit={handleSubmit}>
              <div className="mb-2 mt-2 flex gap-4">
                <div>
                  <select
                    className="border px-2 py-1 rounded text-base font-semibold"
                    name="year"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                  >
                    <option value="" disabled>
                      Select Year
                    </option>
                    {Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i).map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <button
                    type="submit"
                    className="bg-orange-600 py-1 w-[150px] text-base font-semibold text-white rounded cursor-pointer hover:bg-orange-500"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Salary Month Cards */}
      <SalaryMonthCard data={payroll} selectedYear={selectedYear} userData = {userData} />
    </>
  );
};

const SalaryMonthCard = ({ data, selectedYear, userData }) => {
  const [loading, setLoading] = useState(true);

  // Simulate loading delay (replace with actual data fetching logic)
  useEffect(() => {
    if (data) {
      // If data is provided, set loading to false
      setLoading(false);
    } else {
      // If no data, keep loading as true (or handle accordingly)
      setLoading(true);
    }
  }, [data]);

  // Show nothing if no year is selected
  if (!selectedYear) {
    return null;
  }

  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-4 p-4">
      {loading ? (
        <div className="col-span-full flex justify-center items-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
        </div>
      ) : data?.length > 0 ? (
        data.map((payrollData) => (
          <div
            className="bg-white shadow-md rounded-lg p-10 text-center"
            key={`${payrollData.month}-${payrollData.year}`}
          >
            <h1 className="text-2xl font-bold mb-2">
              {payrollData.month.charAt(0).toUpperCase() + payrollData.month.slice(1)} - {payrollData.year}
            </h1>
            <NavLink
              to={`/employee/salary-view`}
              state={{ payrollData, userData }}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Click here
            </NavLink>
          </div>
        ))
      ) : (
        <div className="col-span-full flex justify-center items-center">
          <p className="text-red-500 text-center text-lg px-4 py-2 rounded">
            No Data Available for selected Year. Please Connect to HR
          </p>
        </div>
      )}
    </div>
  );
};





//   <>
    //   <div className="">
    //     <UserProfile />
    //   </div>
    //   <div className="flex justify-center items-center text-center mt-10 h-[60vh]">
    //     <div className="w-[300px] bg-white rounded shadow py-12">
    //       <h1 className="text-xl font-semibold">Select Followings</h1>

    //       <div className="flex-col">
    //         <form onSubmit={handleSubmit}>
    //           <div className="mb-2 mt-2 flex flex-col gap-4">
    //             <label className="text-base font-semibold" htmlFor="year">
    //               Year
    //             </label>
    //             <div>
    //               <select
    //                 className="border px-2 py-1 rounded text-base font-semibold"
    //                 name="year"
    //                 value={year}
    //                 onChange={(e) => setYear(e.target.value)} // Update state when year is selected
    //               >
    //                 <option value="" disabled>
    //                   Select Year
    //                 </option>
    //                 {Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i).map((year) => (
    //                   <option key={year} value={year}>
    //                     {year}
    //                   </option>
    //                 ))}
    //               </select>
    //             </div>
    //             <div>
    //               <button
    //                 type="submit"
    //                 className="bg-orange-600 py-1 w-[150px] text-base font-semibold text-white rounded cursor-pointer hover:bg-orange-500"
    //               >
    //                 Submit
    //               </button>
    //             </div>
    //           </div>
    //         </form>
    //       </div>
    //     </div>
    //   </div>
    // </>

    // <>
    //   <div className="">
    //     <UserProfile />
    //   </div>
    //   <div className="flex justify-center items-center text-center mt-10 h-[60vh]">
    //     <div className="w-[300px] bg-white   rounded shadow py-12">
    //       <h1 className="text-xl font-semibold">Select Folowings.</h1>

    //       <div className="flex-col">
    //         <form onSubmit={hadleSubmit}>
    //           <div className="mb-2 mt-2 flex flex-col gap-4">
    //             <label className="text-base font-semibold" htmlFor="">
    //               Month
    //             </label>
    //             <div>
    //               <input
    //                 className="border px-2 py-1 rounded text-base font-semibold"
    //                 type="date"
    //               />
    //             </div>
    //             <div>
    //               <button className="bg-orange-600  py-1 w-[150px] text-base font-semibold text-white rounded cursor-pointer hover:bg-orange-500">
    //                 Submit
    //               </button>
    //             </div>
    //           </div>
    //         </form>
    //       </div>
    //     </div>
    //   </div>
    // </>