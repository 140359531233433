const baseUrl = process.env.REACT_APP_BASEURL;

export const getCompleteUrl = (url) => {
  if (!url) {
    return '/default-image.png'; // Fallback image if URL is missing
  }
  if (url.startsWith("https") || url.startsWith("http")) {
    return url;
  } else {
    return `${baseUrl}${url}`;
  }
};




// const baseUrl = process.env.REACT_APP_BASEURL;
// export const getCompleteUrl = (url) => {
//     if (url.startsWith("https") || url.startsWith("http")) {
//       return url;
//     } else {
//       return `${baseUrl}${url}`;
//     }
//   }