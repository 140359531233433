import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postData1, getEmployeeLeavesDetails, getEmployeeYearPayRoll } from "../../APIs";

// Async Thunk to fetch employee leaves
export const fetchEmployeeLeaves = createAsyncThunk(
  "leave/fetchEmployeeLeaves",
  async (_, { rejectWithValue }) => {
    try {
      const data = await getEmployeeLeavesDetails();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async Thunk to fetch employee payroll by year
export const fetchEmployeePayRoll = createAsyncThunk(
  "payroll/fetchEmployeePayRoll",
  async (payload, { rejectWithValue }) => { 
    try {
      const { year } = payload; 
      const data = await getEmployeeYearPayRoll(year);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Slice definition
export const userSlice = createSlice({
  name: "user",
  initialState: {
    userData: {},
    holidayList: [],
    leaves: [], // Use consistent naming
    // payroll: [], // Uncommented and initialized
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setHolidayList: (state, action) => {
      state.holidayList = action.payload;
    },
    // setLeaveList: (state, action) => {
    //   state.leaves = action.payload; // Consistent naming
    // },
    // setPayroll: (state, action) => {
    //   state.payroll = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Employee Leaves
      .addCase(fetchEmployeeLeaves.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchEmployeeLeaves.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.leaves = action.payload;
      })
      .addCase(fetchEmployeeLeaves.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Fetch Employee Payroll
      .addCase(fetchEmployeePayRoll.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchEmployeePayRoll.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.payroll = action.payload;
      })
      .addCase(fetchEmployeePayRoll.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

// Export actions and reducer
export const { setUserData, setHolidayList } = userSlice.actions;

// Async action to fetch holiday list
export const fetchHolidayList = () => async (dispatch) => {
  try {
    const data = {
      is_deleted: false,
    };
    const res = await postData1("api/v1/list_holidays/", data, true);
    if (res.status_code === 200) {
      dispatch(setHolidayList(res?.data?.data));
    }
  } catch (error) {
    console.error("Error fetching holiday list:", error);
  }
};

export default userSlice.reducer;





// import { createSlice } from "@reduxjs/toolkit";
// import { postData1 } from "../../APIs";

// export const userSlice = createSlice({
//   name: "user",
//   initialState: {
//     userData: {},
//     holidayList: [],
//   },
//   reducers: {
//     setUserData: (state, action) => {
//       state.userData = action.payload;
//     },
//     setHolidayList: (state, action) => {
//       state.holidayList = action.payload;
//     },
//   },
// });

// // Action creators are generated for each case reducer function
// export const { setUserData, setHolidayList } = userSlice.actions;

// export const fetchHolidayList = () => async (dispach) => {
//   let data = {
//     is_deleted: false,
//   };

//   const res = await postData1("api/v1/list_holidays/", data, true);
//   if (res.status_code === 200) {
//     dispach(setHolidayList(res?.data?.data));
//   }
// };

// export default userSlice.reducer;
